var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('k-crud-page',{scopedSlots:_vm._u([{key:"condition",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap my-4"},[_c('k-group-select',{attrs:{"items":_vm.orgTreeList,"label":"机构名称"},model:{value:(_vm.cruddataMX.conditions.orgIdList),callback:function ($$v) {_vm.$set(_vm.cruddataMX.conditions, "orgIdList", $$v)},expression:"cruddataMX.conditions.orgIdList"}}),_c('k-select',{attrs:{"items":_vm.taskTypeList,"label":"任务类型"},model:{value:(_vm.cruddataMX.conditions.taskType),callback:function ($$v) {_vm.$set(_vm.cruddataMX.conditions, "taskType", $$v)},expression:"cruddataMX.conditions.taskType"}}),_c('k-select',{attrs:{"items":_vm.statusList,"label":"执行状态"},model:{value:(_vm.cruddataMX.conditions.status),callback:function ($$v) {_vm.$set(_vm.cruddataMX.conditions, "status", $$v)},expression:"cruddataMX.conditions.status"}}),_c('k-select',{attrs:{"items":_vm.resultList,"label":"执行结果"},model:{value:(_vm.cruddataMX.conditions.result),callback:function ($$v) {_vm.$set(_vm.cruddataMX.conditions, "result", $$v)},expression:"cruddataMX.conditions.result"}})],1),_c('div',{staticClass:"d-flex flex-wrap my-4"},[_c('k-date-range-select',{attrs:{"label":"计划完成时间"}}),_c('div',{staticClass:"d-flex justify-end ml-auto"},[_c('k-primary-button',{staticClass:"my-1 mr-4",attrs:{"text":"检索","icon":"mdi-magnify"},on:{"click":function($event){_vm.crudConditionsMX().resetPage()}}})],1)],1),_c('v-divider')]},proxy:true},{key:"resultlist",fn:function(){return [_c('k-crud-table',{attrs:{"headers":_vm.columns,"items":_vm.cruddataMX.dataList,"value":_vm.cruddataMX.pagination.current,"total":_vm.cruddataMX.pagination.total},on:{"pageChanged":function (page) {
            _vm.crudPaginationMX().change(page);
          }},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
          var item = ref.item;
return [_c('k-primary-button',{directives:[{name:"show",rawName:"v-show",value:(item.statusCode == '01'),expression:"item.statusCode == '01'"}],attrs:{"text":"开始"},on:{"click":function($event){return _vm.updateStatus(item)}}}),_c('k-primary-button',{directives:[{name:"show",rawName:"v-show",value:(item.statusCode == '02'),expression:"item.statusCode == '02'"}],attrs:{"text":"结束"},on:{"click":function($event){return _vm.updateStatus(item)}}}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(item.statusCode == '03'),expression:"item.statusCode == '03'"}],attrs:{"text":"","color":"green"},on:{"click":function($event){_vm.crudActionMX().details(_vm.crud, item.uuid)}}},[_vm._v("详情 ")]),_c('k-primary-button',{directives:[{name:"show",rawName:"v-show",value:(item.resultCode == '02'),expression:"item.resultCode == '02'"}],attrs:{"text":"重新执行"},on:{"click":function($event){return _vm.updateStatus(item)}}})]}},{key:"roleVos",fn:function(ref){
          var item = ref.item;
return [(
              item.location !== '' &&
              item.location !== null &&
              item.location !== undefined
            )?_c('span',[_vm._v(_vm._s(item.location))]):_c('span',[_vm._v(_vm._s(item.area))])]}}])}),_c('k-crud-dialog',{attrs:{"title":_vm.crud.title,"maxwidth":"600px","actionshow":_vm.actionShow},on:{"save":function () {
            _vm.crudActionMX().saveItem();
          },"close":function () {
            _vm.crudActionMX().closeEditingPage(_vm.crud);
          }},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c('v-form',{ref:_vm.crud.form.ref},[_c('div',{staticClass:"d-flex flex-wrap my-4"},[_c('div',[_c('div',[_vm._v("时间："+_vm._s(_vm.cruddataMX.data.actualCompleteTime))]),_c('div',[_vm._v("机构："+_vm._s(_vm.cruddataMX.data.orgName))]),_c('div',[_vm._v("柜员："+_vm._s(_vm.cruddataMX.data.executorName))]),_c('div',[_vm._v("柜台："+_vm._s(_vm.cruddataMX.data.location))]),_c('div',[_vm._v("状态："+_vm._s(_vm.cruddataMX.data.statusCode))]),_c('div',[_vm._v("描述："+_vm._s(_vm.cruddataMX.data.remark))]),_c('div',[_vm._v("金额：0.00")])]),_c('div',{staticClass:"ml-4"},[_c('v-img',{staticClass:"grey darken-4",attrs:{"src":"https://picsum.photos/350/165?random","width":"320","height":"200"}})],1)])])]},proxy:true}]),model:{value:(_vm.crud.switch),callback:function ($$v) {_vm.$set(_vm.crud, "switch", $$v)},expression:"crud.switch"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }