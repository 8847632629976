<template>
  <div>
    <k-crud-page>
      <template #condition>
        <div class="d-flex flex-wrap my-4">
          <k-group-select
            v-model="cruddataMX.conditions.orgIdList"
            :items="orgTreeList"
            label="机构名称"
          />
          <k-select
            v-model="cruddataMX.conditions.taskType"
            :items="taskTypeList"
            label="任务类型"
          />
          <k-select
            v-model="cruddataMX.conditions.status"
            :items="statusList"
            label="执行状态"
          />
          <k-select
            v-model="cruddataMX.conditions.result"
            :items="resultList"
            label="执行结果"
          />
        </div>
        <div class="d-flex flex-wrap my-4">
          <k-date-range-select label="计划完成时间" />
          <div class="d-flex justify-end ml-auto">
            <k-primary-button
              class="my-1 mr-4"
              text="检索"
              icon="mdi-magnify"
              @click="crudConditionsMX().resetPage()"
            />
          </div>
        </div>
        <v-divider />
      </template>

      <template #resultlist>
        <k-crud-table
          :headers="columns"
          :items="cruddataMX.dataList"
          :value="cruddataMX.pagination.current"
          :total="cruddataMX.pagination.total"
          @pageChanged="
            (page) => {
              crudPaginationMX().change(page);
            }
          "
        >
          <template #actions="{ item }">
            <k-primary-button
              v-show="item.statusCode == '01'"
              text="开始"
              @click="updateStatus(item)"
            >
            </k-primary-button>
            <k-primary-button
              v-show="item.statusCode == '02'"
              text="结束"
              @click="updateStatus(item)"
            >
            </k-primary-button>
            <v-btn
              v-show="item.statusCode == '03'"
              text
              color="green"
              @click="crudActionMX().details(crud, item.uuid)"
              >详情
            </v-btn>
            <k-primary-button
              v-show="item.resultCode == '02'"
              text="重新执行"
              @click="updateStatus(item)"
            >
            </k-primary-button>
          </template>
          <template #roleVos="{ item }">
            <span
              v-if="
                item.location !== '' &&
                item.location !== null &&
                item.location !== undefined
              "
              >{{ item.location }}</span
            >
            <span v-else>{{ item.area }}</span>
          </template>
        </k-crud-table>
        <k-crud-dialog
          v-model="crud.switch"
          :title="crud.title"
          maxwidth="600px"
          :actionshow="actionShow"
          @save="
            () => {
              crudActionMX().saveItem();
            }
          "
          @close="
            () => {
              crudActionMX().closeEditingPage(crud);
            }
          "
        >
          <template #dialogContent>
            <v-form :ref="crud.form.ref">
              <div class="d-flex flex-wrap my-4">
                <div>
                  <div>时间：{{ cruddataMX.data.actualCompleteTime }}</div>
                  <div>机构：{{ cruddataMX.data.orgName }}</div>
                  <div>柜员：{{ cruddataMX.data.executorName }}</div>
                  <div>柜台：{{ cruddataMX.data.location }}</div>
                  <div>状态：{{ cruddataMX.data.statusCode }}</div>
                  <div>描述：{{ cruddataMX.data.remark }}</div>
                  <div>金额：0.00</div>
                </div>
                <div class="ml-4">
                  <v-img
                    src="https://picsum.photos/350/165?random"
                    width="320"
                    height="200"
                    class="grey darken-4"
                  ></v-img>
                </div>
              </div>
            </v-form>
          </template>
        </k-crud-dialog>
      </template>
    </k-crud-page>
  </div>
</template>
<script>
import KPrimaryButton from '../../../components/koi/button/KPrimaryButton.vue';

export default {
  components: { KPrimaryButton },
  data() {
    return {
      actionShow: false,
      dates: [],
      taskTypeList: [],
      statusList: [],
      resultList: [],
      crud: {
        name: '任务详情',
        title: '', // 在Mixin中被修改
        switch: false,
        uploading: false,
        loading: false,
        form: {
          ref: 'taskListModalForm',
        },
        default: {
          actualCompleteTime: '',
          orgName: '',
          location: '',
          executorName: '',
          statusCode: '',
          remark: '',
          url: '',
          previewUrl: '',
        },
      },
      columns: [
        { text: '序号', value: 'indexNum' },
        { text: '机构名称', value: 'orgName', sortable: false },
        { text: '操作位置', value: 'roleVos', sortable: false },
        { text: '任务类型', value: 'taskType', sortable: false },
        { text: '任务编号', value: 'taskNo', sortable: false },
        { text: '任务名称', value: 'taskName', sortable: false },
        { text: '计划完成时间', value: 'planCompleteTime' },
        { text: '执行状态', value: 'statusName', sortable: false },
        { text: '执行结果', value: 'resultName', sortable: false },
        { text: '完成时间', value: 'actualCompleteTime' },
        { text: '执行结果描述', value: 'remark', sortable: false },
        { text: '执行人', value: 'executorName', sortable: false },
        { text: '操作', value: 'actions', sortable: false, align: 'center' },
      ],
      // 测试机构
      orgTreeList: [],
    };
  },
  created() {
    this.getInitData();
    this.crudInitMX(this.crud, {
      list: this.$api.taskList.taskList.getTaskList,
      details: this.$api.taskList.taskList.getTaskDetail,
    });
  },
  methods: {
    // 画面初期(下拉数据)数据获取
    getInitData() {
      this.$api.taskList.taskList.init().then((res) => {
        this.orgTreeList = res.data[0].orgTreeList;
        this.taskTypeList = res.data[0].analysisItemList;
        this.statusList = res.data[0].taskExecStatusList;
        this.resultList = res.data[0].taskExecResultList;
      });
    },
    // 任务执行状态更新
    updateStatus(item) {
      this.$api.taskList.taskList.updateTaskStatus(item).then((res) => {
        if (res.code == 200) {
          this.crudConditionsMX().resetPage();
        }
      });
    },
  },
};
</script>
